import React from 'react'

import Logo from 'static/svg/crypto-pay-logo.svg'

export default function Home() {
  return (
    <div className="flex h-screen items-center justify-center">
      <div>
        <Logo width="300" height="100" />
        <div className="text-center text-18">welcome to Crypto Pay!</div>
      </div>
    </div>
  )
}
